export enum AnalyticsEvent {
  AssistantQuestionAsked = "Assistant Question Asked",
  AssistantSettingsSaved = "Assistant Settings Changed",
  AssistantChatRestarted = "Chat Restarted",
  TaskAdded = "Task Added",
  TaskSnoozed = "Task Snoozed",
  TaskPriorityChanged = "Task Priority Changed",
  TaskDeleted = "Task Deleted",
  TaskChecked = "Task Checked",
  TaskOpenedInNotion = "Task Opened in Notion",
  TaskConversationOpened = "Task Conversation Opened",
  TimesheetSubmitted = "Timesheet Submitted",
  TimesheetEdited = "Timesheet Edited",
  TimesheetDeleted = "Timesheet Deleted",
  TranslationDone = "Translation Done",
  MissiveContactsFetched = "Missive Contacts Fetched",
  HubspotContactsFetched = "Hubspot Contacts Fetched",
  HubspotContactCreated = "Hubspot Contact Created",
  HubspotContactUpdated = "Hubspot Contact Updated",
  HubspotContactDeleted = "Hubspot Contact Deleted",
  HubspotTaskCreated = "Hubspot Task Created",
  HubspotTaskUpdated = "Hubspot Task Updated",
  HubspotTaskDeleted = "Hubspot Task Deleted",
  NotionDataFetched = "Notion Data Fetched",
  NotionCommentAdded = "Notion Comment Added",
  NotionProjectUpdated = "Notion Project Updated",
}
